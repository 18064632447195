iframe.inventicatorFrame {
  display: block;
  width: 100%;
  height: 1900px;
  border: 0px solid #666;
  box-sizing: border-box;
  transform-origin: center top;
  transition: transform .05s linear;
  backface-visibility: hidden;
}

iframe.guideVid {
	height:300px;
	width: 100%;
	display: block;
}

/* Preloader */
#preloader {
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:#fff; /* change if the mask should have another color then white */
	z-index:99; /* makes sure it stays on top */
}

#status {
	width:200px;
	height:200px;
	position:absolute;
	left:50%; /* centers the loading animation horizontally one the screen */
	top:50%; /* centers the loading animation vertically one the screen */
	background-image:url(../../img/status.gif); /* path to your loading animation */
	background-repeat:no-repeat;
	background-position:center;
	margin:-100px 0 0 -100px; /* is width and height divided by two */
}

.row{
	max-width: 100%;
}

.appPlaceHolder{
	width: 800px;
	height: 1900px;
	background-color: #999;
}

//moved from head of Rog's app
.question {
	margin: 1em;
	padding: 1em;
background: #eff;
}

.debugger {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	overflow: scroll;
	background: #eee;
	padding: 1em;
	display: none;
}

//new app
.chart{
    position: fixed;
    margin-right: 0px;
    right: 30px;
    top: 10px;
    z-index: 100;
    // display: none;
}

p.total{
	float:right;
	padding-right: 15px;
	
	@media #{$small-only} { 
		width: 100%;
		display: block;
		padding-left: 30px;	
	}
}

p.guide-link{
	@media #{$small-only} { 
		display: none;
	}
}

#begin{
	padding: 1.5rem 0;
}

#cat-nav{
	// padding-bottom: 1.5rem;
	background-color: #e8e8e8;

	.sub-nav dd{
		margin-left: 0rem;
	}
	
	@media #{$medium-up} { 
		.sub-nav dd{
			margin-left: 1rem; 
		}
	}
	@media #{$small-only} { 
		.sub-nav dd a{
			padding-left: .5rem;
			padding-right:.5rem;		
		}


	}

}



div#appLogoContainer{
	// position: relative;
	// width: 100%;
	// background-image: url(../../img/inventicator/inventicator.jpg);
	
	img#appLogo{
	width: 100%;
	}
}

div#totalICQ{
	position: relative;
	background-color: #0078a0;
	padding: 8px;
	h2{color: #fff;}

}



div.switch{
	span{
	position: relative;
	left: 8px;
    top: -7px;
	}
}

