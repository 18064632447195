@media #{$small-only} { 
    .hide-on-small{
        display: none;  
    }
}

.top-bar .name h1 a#site-name{
	@media #{$small-only} { 
		color: transparent;
	}

	&.phone{
		color: transparent;
	}
}

.top-bar .name h1 a:before {
    background-image: url(../../img/invention-city-logo-wht.png);
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    height: 28px;
    margin-right: 0px;
    position: relative;
    width: 44px;
    background-size: contain;
    top: 6px;

    
}

.bhr-banner{
    padding: 5px;
    background-color: #008CBA;
    color: #fff;
    a{
        text-decoration: underline;
        color: #fff;
    }
}

.allSite-banner{
    padding: 5px;
    /*margin-top:1.25rem;*/
    font-size: 1.25rem;
    background-image: url(../../img/bg.png);
    background-size: 25px;
    margin-bottom:0px;

    a{
        /*text-decoration: underline;*/
        /*color: #fff;*/
        font-size: 1.25rem;
        img.pizza{
            height: 100px;
            &:hover{
                opacity: .75;
            }
        }
        img.kick{
            height: 50px;

        }

    } 

    a.reinvented{
        top:10px;
        position: relative;
        &:hover{
            text-decoration: underline;
        }
    }
    .button.success{
        background-color: transparent;
        border: 5px solid #368a55;
        color: #000;
        &:hover{ 
            background-color: #368a55;
            color: #fff;
        }
        top:9px;
        padding:1rem;
    }
}

a.ss-social{
    font-size: 1.5rem;
    top: 6px;
    position: relative;
}

p.share{
    width: 100%;
    text-align: right;
    
}