#home-fullscreen{
	
	// background: url(../../img/home/inventioncity.jpg)
	// background: url(../../img/home/invention-city-home_reg3_med.jpg)
	// background: url(../../images/home/home-2-bg.jpg)
	// top / 100% /* position / size */
    repeat: no-repeat                /* repeat */; 
    background-position: center 50%;
    background-size: 100%;
    background-color: #333;
    
    // filter: brightness(50%);
    @media #{$large} { 
    	//background-size: 100%;
    }
    @media #{$medium} { 
    	//background-size: 1400px auto;
    }
    @media #{$small-only} { 
    	background-size: 800px auto;
    	
    }
    height: 100vh;
	margin-top: -45px;

	.row section#section-1{
		margin-top:10%;
	}

	.phone{

	}

	// position: relative;
	// top:-725px;
	// margin-bottom: -525px;
	
	div.logo{
		text-align: center;
		img{
			width:650px;
			@media #{$small-only} {
				margin-top: 20px; 
				width:350px;
			}
			@media #{$landscape}{
				width:375px;
				margin-bottom: 0px;
				}
			@media #{$small-up}{ 
				// width:20px;
			}

		}
		img.phone{
			@media #{$landscape}{
				width:250px;
				
			}
		}
		@media #{$medium-up}{
				// position: absolute; 
				// bottom: 100px;
				// right: 100px;

			}

				
	}
	h1{
		font-size: 3.25em;
		&.phone{
			@media #{$landscape}{
				font-size: 1.75em;
			}
			span.block{
				display: block;
				@media #{$landscape}{
					display: inline-block;
				}
			}
		}

		@media #{$small-only} { 
			font-size: 1.5em;
			margin-top: 40px;
			
			margin-bottom: 60px;

		}
		@media #{$landscape}{
				margin-top: 20px;

			}


		color:#fff;
		text-align: center;
		span.caps{
			font-weight: 900;
			text-transform: uppercase;
		}
		span.block{
			@media #{$medium-only}{ 
				// display: block;
				// font-size: .5em;
			}
			@media #{$landscape}{
				
			}
		}
		/* tag: */
		// line-height: 97px;
		text-shadow: 1px 1px 2px #030303;
		margin-top:  80px;
		margin-bottom: 80px;
		


	}
	section.home-cta{
		
		@media #{$medium-up} { 
			margin-top:20px;
			a.secondary{
			margin-right: 50px;
			}
		}

		&.phone{
			@media #{$landscape}{
				margin-top:-40px;	
			}
		}	
	}
	
}

p.inventicateButton{
	a{
		font-size: 2em;
    // border: 1px solid #fff;
    // border-radius: 5px;
    // cursor: pointer;
    // line-height: normal;
    // margin: 0 0 1.25rem;
    // position: relative;
    // text-align: center;
    // display: inline-block;
    // padding: 1rem 2rem 1.0625rem;
    // font-size: 1rem;
    // background-color: rgba(67,172,106,1);
    // color: #FFF;
    // transition: background-color 300ms ease-out;
	}
}

p.bbb {
	margin-top: 20px;
	a{
		color: #fff;
		&:hover{text-decoration: underline;}
	}
	
}